/* styles.css #ffffff*/

/* Синий фон для всей страницы */
body {
    background-color: #0e0f46;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Блок с полями ввода в центре страницы */
#change-password-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 5px
}

/* Границы и скругленные углы для блока с полями */
#change-password-form .MuiPaper-root {
    border: 1px solid #ccc;
    border-radius: 8px;
}

/* Границы и скругленные углы для блока с полями */
#change-password-form .css-t039ty-MuiButtonBase-root-MuiButton-root-RaSaveButton-root.Mui-disabled {
    color: #ffffff;
    box-shadow: none;
    background-color: rgb(80, 117, 222);
}

/* Стилизация текстовых полей, сохраняя цвет фона */
#change-password-form input[type="text"],
#change-password-form input[type="password"] {
    background-color: #fff; /* Цвет фона полей */
    color: #000; /* Цвет текста в полях */
}

.MuiButtonBase-root {
    background-color: #0e0f46;
}

